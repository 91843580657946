import { defineNuxtRouteMiddleware, navigateTo, useRequestURL } from '#imports';
import { httpStatus } from '~dsc/utils/http-status';
import { paths } from '@@/src/plugins/paths';

const redirects = {
    '/perfect-autopesu/autopesutuotteet/perfect-tornado': '/autopesutuotteet/perfect-tornado',
    '/autopesuohjeet': '/autonpesuohjeet',
    '/perfect-autopesu/autopesutuotteet/perfect-premium': '/autopesutuotteet/perfect-premium',
    '/perfect-autopesu/autopesutuotteet/perfect-shine': '/autopesutuotteet/perfect-shine',
    '/perfect-autopesu/autopesutuotteet/perfect-express': '/autopesutuotteet/perfect-express',
    '/usein-kysyttya': '/asiakaspalvelu/usein-kysyttya',
    '/autopesuohjeet-st1-way-sovellus': '/autonpesuohjeet/st1-way-sovellus',
    '/autopesuohjeet/osto-opas': '/autonpesuohjeet/osto-opas',
    '/autopesuohjeet/pesuohje': '/autonpesuohjeet/pesuohje',
    '/talvi-pesupaketti': '/autopesutuotteet/perfect-talvi-pesupaketti',
    '/testisivu': '/',
    '/kaksi-parasta-pesua': '/',
    '/perfect-tornado-autopesu-puoleen-hintaan': '/',
    '/autopesuohjeet/autopesuklinikka': '/autonpesuohjeet/autopesuklinikka',
    '/perfect-tornado-kaksi-yhden-hinnalla': '/',
    '/perfect-shine-kaksi-yhden-hinnalla': '/',
    '/perfect-express-kaksi-yhden-hinnalla': '/',
    '/perfect-autopesu/saannollisen-autopesun-edut': '/perfect-autopesu/saannollisen-pesun-edut',
    '/perfect-premium-kaksi-yhden-hinnalla': '/',
    '/shell-ruskeasannan-autopesu-uudistuu': '/perfect-syksy-pesupaketti',
    '/perfect-autopesu/asiakaspalvelu/ajankohtaista-perfect-autopesuista': '/asiakaspalvelu/ajankohtaista',
    '/pesuohjelmien-vertailu': '/autopesutuotteet/pesuohjelmien-vertailu',
    '/lahti-kivistonmaen-pesuhalli-avattu': '/',
    '/superjasen': '/superjasen-tornado',
    '/superjasen/tornado': '/superjasen-tornado',
    '/autopesupaketit-yrityksille/perfect-express-50kpl': '/autopesutuotteet/pesupaketit-yrityksille',
    '/autopesupaketit-yrityksille/perfect-shine-50kpl': '/autopesutuotteet/pesupaketit-yrityksille',
    '/autopesupaketit-yrityksille/perfect-premium-50kpl': '/autopesutuotteet/pesupaketit-yrityksille',
    '/autopesupaketit-yrityksille/perfect-tornado-50kpl': '/autopesutuotteet/pesupaketit-yrityksille',
    '/autopesupaketit-yrityksille': '/autopesutuotteet/pesupaketit-yrityksille',
    '/autopesupaketit-yrityksille/perfect-tornado-100kpl': '/autopesutuotteet/pesupaketit-yrityksille',
    '/autopesupaketit-yrityksille/perfect-shine-100kpl': '/autopesutuotteet/pesupaketit-yrityksille',
    '/perfect-premium-autopesu-puoleen-hintaan': '/',
    '/autopesulat/shell-ja-st1-perfect-pesuasemien-happy-hour': '/autopesutuotteet/happy-hour',
    '/survey-hero': '/autonpesuohjeet/autopesuklinikka',
    '/golf': '/',
    '/autopesutuotteet/pesupaketit-takseille/perfect-express': '/taksit',
    '/autopesupaketit-takseille/perfect-premium': '/taksit',
    '/shell_porvoo_kuninkaanportin_pesuhalli_avattu_uudistuneena': '/',
    '/shell-kuusankoski-katajaharjun-pesuhallia-remontoidaan': '/',
    '/shell_kuusankoski_katajaharjun_autopesuhalli_avattu_uudistettuna': '/',
    '/kevat-pesupaketti': '/perfect-kevat-pesupaketti',
    '/perfect-autopesu/autopesutuotteet/perfect-harjaton': '/autopesutuotteet/perfect-harjaton',
    '/perfect-autopesu/autopesutuotteet/perfect-tuplakiilto': '/',
    '/perfect-shine-3-kpl': '/autopesutuotteet/perfect-shine',
    '/perfect-harjaton-kaksi-yhden-hinnalla': '/',
    '/perfect-express-autopesu-puoleen-hintaan': '/',
    '/perfect-harjaton-autopesu-puoleen-hintaan': '/',
    '/heinaspecial': '/',
    '/sarjapesu': '/perfect-autopesu/sarjapesu',
    '/pesukadun-kaytto': '/autonpesuohjeet/pesukadun-kaytto',
    '/shell_tullinpuomin_pesukatu_avattu_uudistettuna': '/autopesu-toolo-meilahti',
    '/shell_itakeskuksen_uusi_pesukatu_avattu': '/autopesu-itakeskus',
    '/osta_meilta_pese_missa_vain': '/',
    '/kesan-nopeat-pesut': '/',
    '/perfect-shine-autopesu-puoleen-hintaan': '/',
};

export default defineNuxtRouteMiddleware(route => {
    const query = Object.keys(route.query).map(key => `${key}=${route.query[key]}`).join('&');

    if (import.meta.server) {
        const url = useRequestURL();
        if (url.origin !== paths['autopesu-domain']['fi-FI']) {
            return navigateTo(
                `${paths['autopesu-domain']['fi-FI']}${route.path}${query.length > 0 ? `?${query}` : ''}`,
                {
                    external: true,
                    redirectCode: httpStatus.PERMANENT_REDIRECT,
                },
            );
        }
    }

    if (Object.keys(redirects).includes(route.path)) {
        return navigateTo(
            `${redirects[route.path]}${query.length > 0 ? `?${query}` : ''}`,
            {
                external: false,
                redirectCode: httpStatus.PERMANENT_REDIRECT,
            },
        );
    }
});
